import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: 'bold',
      color: 'heading'
    }} mdxType="Text">
Hello.<br />
I'm Min Choro
    </Text>
    <p>{`테크를 좋아합니다.`}<br />{`
Maximalist 입니다.`}<br />{`
남들이 하는건 당연히 해야하고 안하는 것도 해야합니다.`}<br />{`
남들이 가지고 있는건 당연히 가져야고 안하는 것도 가져야합니다.`}</p>
    <div>
    <a href="https://portfolio.superclass.io/" target="_blank">안녕하세요, 민초로 입니다 👋</a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      